import React, { useState } from "react"
import * as styles from "./signinform.module.css"
import util from "../../../js/util"
import apiClient from "../../../js/apiclient"
import { VendorsAuthBox } from "../../vendors/vendors-auth-box"
import { PassInput } from "../passinput/passinput"

const signInError = <>
  <div className={ styles.authError }>
    <p>Invalid user name or password.</p>
  </div>
</>

const SignInForm = () => {
  const [credentials, setCredentials] = useState({
    userName: "",
    userPassword: ""
  })

  const [isSignInError, setHasSignInError] = useState(false)
  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    setHasSignInError(false)

    const newCredentials = Object.assign({}, credentials)
    newCredentials[name] = value

    setCredentials(newCredentials)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    apiClient.doLogin(credentials).then(response => {
      if(response.status === 200) {
        util.navigateToPage("/dashboard")
      } else if (response.status === 401) {
        setHasSignInError(true)
      } else {
        console.log("Login response: ", response)
      }
    })
  }

  return (
    <div id="signin" className={ styles.signin }>
      <h3>Sign in</h3>
      { isSignInError  && signInError }
      <form onSubmit={handleSubmit}>
        <label>
          <div className={styles.mandatory}>
            <span>*</span> Login name:
          </div>
          <input
            name="userName"
            type="text"
            placeholder="user name or email"
            onChange={handleInputChange} />
        </label>
        <div style={{
          height: "15px"
        }}></div>
        <PassInput name="userPassword" labelText="Password:" inputChangeHandler={handleInputChange} />
        <input type="submit" value="Sign In" />
      </form>
      <div style={{
        textAlign: "center",
        fontSize: "1.07rem"
      }}>
        <a href="/forgot-password">Forgot password?</a>
      </div>
      <VendorsAuthBox />
      <div className={ styles.suiteBlock }></div>
    </div>
  )
}

export default SignInForm