import React from "react"
import * as styles from "./auth-btn.module.css"
import util from "../../../js/util"
import { useSelector } from "react-redux"
import { VscGithub } from "react-icons/vsc"

export const GithubAuthBtn = () => {
  const protocolHost = window.location.protocol + "//" + window.location.host;
  const configs = useSelector((state) => state.vendorConfigs.configs)
  const vendorConfig = configs["github"][0]

  const clickHandler = () => {
    util.navigateToPage("https://github.com/login/oauth/authorize?" + new URLSearchParams({
      client_id: vendorConfig.clientId,
      scope: "read:user user:email",
      redirect_uri: `${protocolHost}/api/do_auth_github`
    }))
  }

  return (
    <div className={styles.authBtn} onClick={clickHandler}>
      <span style={{
        fontSize: "1.4rem"
      }}><VscGithub/></span>&nbsp;&nbsp;Continue with GitHub
    </div>
  )
}