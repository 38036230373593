import React from 'react';
import { GoogleAuthBtn } from "./google/auth-btn"
import * as styles from './vendors.module.css'
import { GithubAuthBtn } from "./github/auth-btn"

export const VendorsAuthBox = () => {
  return(
    <div className={styles.vendorsbox}>
      <hr/>
      <GoogleAuthBtn />
      <GithubAuthBtn />
    </div>
  )
}