import React from "react"
import DocLogo from "../doclogo/doclogo"
import * as layoutStyle from "./layout.module.css"

const LoginLayout = ({children}) => {
    return (
        <div id="layout" className={ layoutStyle.layout }>
          { children }
        </div>
    )
}

export default LoginLayout