import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import LoginLayout from "../components/layouts/loginlayout"
import util from "../js/util"
import Loader from "../components/common/loader/loader"
import apiClient from "../js/apiclient"
import BaseLayout from "../components/layouts/baselayout"
import SignInForm from "../components/forms/signinform/signinform"
import { useDispatch } from "react-redux"
import { setVendorConfigs } from "../state/vendorConfigSlice"

const IndexPage = () => {
  const dispatch = useDispatch()
  const [initialized, setInitialized] = useState(false)
  
  useEffect(() => {
    if (!initialized) {
    apiClient.currentLogin().then(response => {
      if (response.status !== 200) {
        if (response.status === 403) {
          apiClient.getVendorConfigs().then(response => {
            response.json().then(configs => {
              dispatch(setVendorConfigs(configs))
              setInitialized(true)
            })
          })
        }
      } else {
        util.navigateToPage("/dashboard")
      }
    })
  }})

  let content = <Loader />

  if (initialized) {
    content = <LoginLayout>
      <SignInForm />
      <div style={{
        width: "fit-content",
        margin: "0 auto",
        textAlign: "center"
      }}>Don't have an account yet? <a href="/signup">Sign Up</a></div>
    </LoginLayout>
  }

  return (
    <>
      <BaseLayout />
      {content}
    </>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home">
  <script src="https://accounts.google.com/gsi/client" />
</Seo>

export default IndexPage
