import React, { useEffect } from "react"
import util from "../../../js/util"

export const GoogleAuthBtn = () => {
  const containerId = "google-auth"
  const btnOpts = { theme: "outline", size: "large", width: 320 }

  useEffect(() => {
    util.initGoogleIdentityServices()
    util.renderSignInWIthGoogleButton(containerId, btnOpts)
  }, [])

  return (
    <div id={ containerId } style={{
      margin: "0 auto",
    }}></div>
  )
}