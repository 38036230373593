import React, { useState } from "react"
import * as styles from "../signinform/signinform.module.css"
import { BiHide, BiShow } from "react-icons/bi"

export const PassInput = ({name, labelText, placeholderText, inputChangeHandler}) => {
  const [hidePassword, setHidePassword] = useState(true)
  const hidePasswordIcon = hidePassword ? <BiShow /> : <BiHide />
  const passwordInputType = hidePassword ? "password" : "text"
  const placeholderUseText = placeholderText || "password"

  const handleHideClick = () => {
    setHidePassword(!hidePassword)
  }

  return (
    <label htmlFor={name} style={{
      position: "relative",
      fontSize: "1rem",
      display: "block"
    }}>
      <div className={styles.mandatory}>
        <span>*</span> { labelText }
      </div>
      <div className={styles.hidePassword} onClick={handleHideClick}>
        <span>{hidePasswordIcon}</span>
      </div>
      <input
        name={name}
        type={passwordInputType}
        placeholder={placeholderUseText}
        onChange={inputChangeHandler} />
    </label>
  )
}