import React from "react"
import { Image } from "react-bootstrap"
import * as logoStyle from "./doclogo.module.css"
import { Link } from "gatsby"

const DocLogo = () => {
  return (
    <div className={logoStyle.logo}>
      <Link to="https://dockovpn.io">
        <div className={ logoStyle.adjustInner + " " + logoStyle.addShadow }>
          <Image src="/images/logo-square-white-2.svg" alt="Site logo" className={ logoStyle.logoImg } />
        </div>
      </Link>
    </div>
  )
}
export default DocLogo